





















import Vue from 'vue';
import Component from 'vue-class-component';
import {createHelpers} from 'vuex-map-fields';
import Card from '@/components/UI/Card/Card.vue';

const { mapFields } = createHelpers({
  getterType: 'getBookmarkField',
  mutationType: 'updateBookmarkField',
});

@Component({
  components: {
    Card
  },
  computed: {...mapFields([
      'mediaList',
      'bookmarkList'
    ]),
  }
})

export default class Shared extends Vue {}
